//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getequipmentApproval } from "@/api/disabilities.js";
export default {
  watch: {
    "form.tf"(val) {
      this.swith = val == "0" ? true : false;
    },
  },
  data() {
    return {
      swith: false,
      dialogVisible: false,
      btnDisab: false,
      options: [
        { name: "是", id: "1" },
        { name: "否", id: "0" },
      ],
      optionss: [
        { name: "是", id: "1" },
        { name: "否", id: "0" },
      ],
      form: {
        id: "",
        tf: "1",
        addEquipment: "0",
        failReason: "",
      },
      registerType: "",
      roleType: "",
    };
  },
  methods: {
    open(id) {
      this.form.id = id;
      this.dialogVisible = true;
      this.registerType = JSON.parse(localStorage.getItem("registerType"));
      this.roleType = JSON.parse(localStorage.getItem("roleType")); //获取用户角色类型
    },
    // 提交
    onSubmit() {
      this.btnDisab = true;
      _getequipmentApproval(this.form)
        .then((res) => {
          if (res.code == "1") {
            this.$emit("query");
            this.btnDisab = false;
            this.dialogVisible = false;
          } else {
            this.btnDisab = false;
          }
        })
        .catch(() => {
          this.btnDisab = false;
        });
    },

    close() {
      this.form = {
        id: "",
        tf: "1",
        addEquipment: "0",
        failReason: "",
      };
    },
  },
};
