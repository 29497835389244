//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _getorderSearch,
  _getequipmentSearch,
  _getequipmentOrder,
  _getequipmentSearchId,
  _getequipmentOrderEdit,
} from "@/api/disabilities.js";
export default {
  data() {
    return {
      title: "",
      btnDisab:false,
      dialogVisible: false,
      options: [],
      disabled: false,
      optionsName: [],
      form: {
        id: "",
        applyStatus: "", //申请类型
        name: "", //设备名称
        moId: "",//工单号id
        orderId: "", //工单号展示出来的id
        type: "", //设备类型
        money: "",//价格
        applyOp: "",//申请原因
        opComment: "",//备注
      },
      rules: {
        name: [
          { required: true, message: "请选择设备名称", trigger: "change" },
        ],
        type: [{ required: true, message: "请输入设备型号", trigger: "blur" }],
        money: [
          { required: true, message: "请输入设备金额", trigger: "blur" },
        ],
        applyOp: [
          { required: true, message: "请输入申请原因", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    open(data) {
      this.dialogVisible = true;
       this.form = Object.assign(data)
    },
    //工单设备的筛选事件
    querySearch(queryString, cb) {
      _getequipmentSearch({ name: queryString }).then((res) => {
        this.optionsName = res.data.map((item) => {
          item.value = item.name;
          return {
            ...item,
          };
        });
        let newData = this.optionsName;
        let results = queryString
          ? newData.filter(this.createFilter(queryString))
          : newData;
        cb(results);
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase()
        );
      };
    },
    handleSelect(item) {
      this.form.name = item.name; //工单设备名称
      this.form.type = item.type;
      this.form.money = item.price;
    },

    // 提交
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnDisab = true
           this.form.money = parseFloat(this.form.money)
            _getequipmentOrderEdit(this.form).then((res) => {
              if (res.code == "1") {
                 this.btnDisab = false
                this.dialogVisible = false;
                this.$message(res.msg);
                this.$emit("query");
              }else{
                 this.btnDisab = false
              }
            }).catch(()=>{
              this.btnDisab = false
            });
        }
      });
    },
    close() {
      this.form = {
        moId: "",
        orderId: "",
        applyStatus: "1",
        name: "",
        id: "",
        type: "",
        money: "",
        applyOp: "",
        opComment: "",
      };
      this.options = [];
      this.$refs["form"].resetFields();
    },
  },
};
